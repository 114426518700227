.header {
    width: 100%;
    padding: 1rem 0.75rem;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    box-shadow: 0px 2px 10px rgba(10, 10, 10, 0.6);
    background-color: var(--color-primary-lightest);
}

.header a>img {
    height: 50px;
}

.header-search {
    flex-grow: 4;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.header-search div:first-child {
    flex-grow: 4;
}

.header-search-box>* {
    padding: 0;
    margin: 0;
}

.header-search-box {
    display: flex;
    column-gap: 0.5rem;
}

.header-search-box input {
    width: 10rem;
    padding: 0 0.5rem;
    border-radius: 10px;
}

.header-search-box button {
    border-radius: 10px;
    padding: 0 0.5rem;
    background-color: var(--color-accent-light);
}

.header-search-box button:hover {
    box-shadow: 1px 1px 7px rgba(10, 10, 10, 0.4);
}

.header-search-box button:active {
    box-shadow: 1px 1px 7px black;
    background-color: var(--color-accent-dark);
}

.header-search-box button:disabled {
    opacity: .7;
    background-color: rgb(187, 187, 187);
}

.menu-button {
    padding: 0;
    font-size: 2rem;
    opacity: 1;
    color: var(--color-primary-dark);
    transition: opacity 250ms ease-out;
}

.fa-x {
    transform: scaleX(1.05) translateX(-2px);
    margin-left: 4px;
}

.menu-button.transition {
    opacity: 0;
}

.menu-button:hover {
    text-shadow: 2px 2px 3px gold;
}

.menu-button:active {
    color: var(--color-primary-darkest);
}

@media screen and (min-width: 600px) {
    .header {
        padding: 1rem 2rem;
    }

    .header-search {
        flex-direction: row;
    }

    .header-search-box input {
        width: 15rem;
        font-size: 1rem;
    }

    .header-search-box button {
        padding: 0 1rem;
        font-size: 1rem;
    }
}
