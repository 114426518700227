.App,
.app-container {
    display: flex;
}

.App {
    width: 100%;
    min-height: 100vh;
}

.app-error {
    padding: 1rem;
    font-size: 0.9rem;
    width: 85%;
    text-align: center;
    background-color: rgba(255, 0, 0, 0.1);
    color: rgb(200, 0, 0);
    border: 3px solid rgb(200, 0, 0);
    border-radius: 1rem;
}

.app-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
    transition: width 0.5s;
}

.page-transition-enter {
    opacity: 0;
    transform: translateX(-100%);
}

.page-transition-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 380ms ease-out;
}

.page-transition-exit {
    opacity: 1;
}

.page-transition-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: all 400ms ease-out;
}
