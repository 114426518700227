.home,
.home-intro,
.home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.home-intro {
    flex-direction: column-reverse;
}

.home {
    font-size: 1.1rem;
    row-gap: 1rem;
}
.home-logo {
    text-align: center;
}

.hp-logo {
    max-width: 80%;
}

.home-content h2 {
    margin: 0;
}

.home-content h4 {
    align-self: flex-end;
}

.home-menu {
    border-radius: 1rem;
    background-color: var(--color-primary-darkest);
    color: var(--color-primary-lightest);
    padding: 0.7rem 1rem;
    font-size: 1.2rem;
    width: 90%;
}

@media screen and (min-width: 600px) {
    .home-intro {
        flex-direction: row;
    }

    .home-logo {
        width: 50%;
    }
}
