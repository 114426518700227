.details {
    font-size: 1rem;
}

.review-text {
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 6px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
}

.add-review {
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    font-size: 1rem;
    background-color: var(--color-primary-light);
    cursor: pointer;
}

.plug-map-container, .review-map-container {
    display: flex;
    padding-top: 1rem;
    gap: 1rem;
    font-size: 0.8rem;
    align-items: center;
    flex-direction: column;
    margin: 0
}

.review-map-container {
    width: 96%;
}

.review-map-container h3 {
    font-size: 1.2rem;
    margin: 0;
}

.plug-map {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.plug-container, .reviews-container {
    padding: 0.25rem;
    padding-left: 0.5rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    align-items: center;
    margin-bottom: 2em;
    overflow-x: auto;
    width: 100%;
}

.search-results h3 {
    font-size: 1.5rem;
}

.plug-map-container,
.plug-container,
.nearby-food,
.nearby-entertainment,
.nearby-stores,
.business-details-container,
.reviews-container {
    border-radius: 1rem;
    box-shadow: 2px 2px 5px rgba(40, 40, 40, 0.6);
}

.details img {
    margin-left: 1rem;
    cursor: pointer;
}

.nearby-containter {
    align-items: center;
    margin-bottom: 2em;

}

.nearby-food {
    margin-top: 2em;
}

.nearby-food,
.nearby-entertainment,
.nearby-stores {
    display: flex;
    flex-direction: column;
    padding: 1em;
    border-radius: 6px;
    border: solid 2px black;
    margin-bottom: 2em;
    overflow-x: auto;
    width: 100%;
    margin-top: 1rem;
    align-items: center;
}

.business-container {
    display: flex;
    column-gap: 1em;
    padding: 1em;
    border-radius: 6px;
    padding: 1em;
    overflow-x: auto;
    width: 96%;
}

.business-details-container {
    width: 10rem;
    display: flex;
    flex-direction: column;
    column-gap: 1em;
    padding: 1em;
    min-width: 200px;
    border-radius: 6px;
    border: solid 2px black;
    padding: 0em 0.5em;
    margin-bottom: 1rem;
    cursor: pointer;
}

.google-map {
    width: 100%;
    flex-grow: 4;
}

@media only screen and (min-width: 768px) {
    .plug-map-container {
        padding-top: 0;
        padding: 1rem;
    }

    .plug-container {
        width: 10rem;
    }

    .plug-map {
        flex-direction: row;
    }

    .business-container {
        justify-content: flex-start;
    }
}

@media only screen and (min-width: 1200px) {
    .plug-map-container {
        margin-bottom: 1rem;
    }

    .plug-container {
        flex-direction: column;
        width: 20rem;
    }
}
