.search-page {
    font-size: 1rem;
}

.search-results {
    display: flex;
    margin: 0rem -2rem;
    gap: 1rem;
    font-size: 0.8rem;
    align-items: center;
    flex-direction: column-reverse;
}

.search-list-container {
    display: flex;
    padding: 0.1rem 0.5rem;
    row-gap: 1rem;
    flex-direction: column;
    max-height: 40rem;
    overflow-y: scroll;
}

.stations-container {
    width: 100%;
    /* width: 12rem; */
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 1rem;
    cursor: pointer;
}

.stations-container :is(p, h1, h2, h3) {
    margin: 0;
}

.stations-container h3:is(h3) {
    font-size: 1.1rem;
}

.search-map {
    height: 40rem;
    flex-grow: 2;
}

.search-results,
.stations-container,
.search-map {
    border-radius: 1rem;
    box-shadow: 2px 2px 5px rgba(40, 40, 40, 0.6);
}

.search-page p {
    width: 96%;
    text-align: center;
}

.google-map {
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .search-results {
        flex-direction: row;
        padding: 0.5rem 1rem;
        margin: 0;
    }

}
