.spinner-bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    text-align: center;
    padding-top: 6rem;
    background-color: rgba(10, 10, 10, 0.7);
    z-index: 1;
    color: white;
    font-size: 2rem;
}

.loading-spinner {
    animation: spinner 2.5s infinite linear;
    font-size: 4rem;
}

@keyframes spinner {
    0% {
        transform: rotate(0) scale(1);
    }

    33% {
        transform: rotate(120deg) scale(0.7);
    }

    66% {
        transform: rotate(240deg) scale(1.3);
    }

    100% {
        transform: rotate(360deg) scale(1);
    }
}
