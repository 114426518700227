@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

:root {
    --color-primary-darkest: #315a5a;
    --color-primary-dark: #527b7b;
    --color-primary-light: #dbeff0;
    --color-primary-lightest: #f6f6ff;
    --color-accent-light: #f9d348;
    --color-accent-dark: #d4b12f;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Oswald", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
