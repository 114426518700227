@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap);
:root {
    --color-primary-darkest: #315a5a;
    --color-primary-dark: #527b7b;
    --color-primary-light: #dbeff0;
    --color-primary-lightest: #f6f6ff;
    --color-accent-light: #f9d348;
    --color-accent-dark: #d4b12f;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Oswald", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.header {
    width: 100%;
    padding: 1rem 0.75rem;
    display: flex;
    align-items: center;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    box-shadow: 0px 2px 10px rgba(10, 10, 10, 0.6);
    background-color: var(--color-primary-lightest);
}

.header a>img {
    height: 50px;
}

.header-search {
    flex-grow: 4;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.header-search div:first-child {
    flex-grow: 4;
}

.header-search-box>* {
    padding: 0;
    margin: 0;
}

.header-search-box {
    display: flex;
    grid-column-gap: 0.5rem;
    -webkit-column-gap: 0.5rem;
            column-gap: 0.5rem;
}

.header-search-box input {
    width: 10rem;
    padding: 0 0.5rem;
    border-radius: 10px;
}

.header-search-box button {
    border-radius: 10px;
    padding: 0 0.5rem;
    background-color: var(--color-accent-light);
}

.header-search-box button:hover {
    box-shadow: 1px 1px 7px rgba(10, 10, 10, 0.4);
}

.header-search-box button:active {
    box-shadow: 1px 1px 7px black;
    background-color: var(--color-accent-dark);
}

.header-search-box button:disabled {
    opacity: .7;
    background-color: rgb(187, 187, 187);
}

.menu-button {
    padding: 0;
    font-size: 2rem;
    opacity: 1;
    color: var(--color-primary-dark);
    transition: opacity 250ms ease-out;
}

.fa-x {
    -webkit-transform: scaleX(1.05) translateX(-2px);
            transform: scaleX(1.05) translateX(-2px);
    margin-left: 4px;
}

.menu-button.transition {
    opacity: 0;
}

.menu-button:hover {
    text-shadow: 2px 2px 3px gold;
}

.menu-button:active {
    color: var(--color-primary-darkest);
}

@media screen and (min-width: 600px) {
    .header {
        padding: 1rem 2rem;
    }

    .header-search {
        flex-direction: row;
    }

    .header-search-box input {
        width: 15rem;
        font-size: 1rem;
    }

    .header-search-box button {
        padding: 0 1rem;
        font-size: 1rem;
    }
}

.react-dd {
    padding: 0.5rem;
    border: 2px solid var(--color-primary-dark);
    width: 100%;
    border-radius: 10px;
    font-size: 0.8rem;
}

.react-dd:hover,
.react-dd.active {
    background-color: var(--color-primary-light);
}

.react-dd-selected p {
    display: flex;
    align-items: center;
}

.react-dd .fa-solid:not(.fa-caret-down) {
    margin-right: 0.75rem;
    font-size: 0.9rem;
}

.react-dd-selected .fa-caret-down {
    margin-left: auto;
}

.react-dd p {
    margin: 0;
}

.react-dd-selected i {
    margin-left: 0.5rem;
}

.react-dd-container {
    position: relative;
    width: 100%;
    height: 0;
}

.react-dd-options {
    position: relative;
    top: 0.5rem;
    background-color: white;
    border: 2px solid var(--color-primary-dark);
    width: 100%;
    height: -webkit-min-content;
    height: min-content;
    z-index: 2;
}

.react-dd-option {
    padding: 0.5rem;
}

.react-dd-option:hover {
    background-color: var(--color-primary-light);
}

.react-dd-option:active {
    background-color: var(--color-primary-dark);
    color: white;
}

@media screen and (min-width: 600px) {
    .react-dd {
        font-size: 1rem;
    }

    .react-dd .fa-solid:not(.fa-caret-down) {
        margin-right: 1rem;
        font-size: 1.2rem;
    }
}

.home,
.home-intro,
.home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.home-intro {
    flex-direction: column-reverse;
}

.home {
    font-size: 1.1rem;
    grid-row-gap: 1rem;
    row-gap: 1rem;
}
.home-logo {
    text-align: center;
}

.hp-logo {
    max-width: 80%;
}

.home-content h2 {
    margin: 0;
}

.home-content h4 {
    align-self: flex-end;
}

.home-menu {
    border-radius: 1rem;
    background-color: var(--color-primary-darkest);
    color: var(--color-primary-lightest);
    padding: 0.7rem 1rem;
    font-size: 1.2rem;
    width: 90%;
}

@media screen and (min-width: 600px) {
    .home-intro {
        flex-direction: row;
    }

    .home-logo {
        width: 50%;
    }
}

.auth,
.auth-input {
    display: flex;
    flex-direction: column;
}

.auth {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
    font-size: 1rem;
}

.auth :is(h1, p) {
    margin: 0;
}

.auth-input {
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
}

.auth-button {
    padding: 0.5rem;
    font-size: 1.1rem;
    font-weight: bold;
    background-color: var(--color-primary-dark);
    color: var(--color-primary-lightest);
}

.sidebar-menu,
.sidebar-menu-inner {
    width: 0;
    font-size: 1.2rem;
    transition: width 0.5s;
    overflow: hidden;
    height: 100%;
}

.sidebar-menu-inner {
    display: flex;
    flex-direction: column;
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
    align-items: center;
    background-color: var(--color-primary-darkest);
    color: var(--color-primary-lightest);
    position: fixed;
    top: 0;
    height: 100vh;
}

.sidebar-menu.active {
    width: 20.5rem;
}

.sidebar-menu.active .sidebar-menu-inner {
    width: 18rem;
}

.sidebar-menu hr {
    width: 75%;
}

.sidebar-menu :is(h1, h2) {
    border-bottom: 2px solid white;
    padding: 0 1rem 0.4rem;
    margin-bottom: 0.5rem;
}

.sidebar-menu a {
    color: inherit;
    text-decoration: none;
    font-weight: bold;
}

.sidebar-near {
    cursor: pointer;
    font-weight: bold;
}

.sidebar-guest {
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    background-color: var(--color-accent-light);
    font-size: 1rem;
}

.sidebar-guest:hover {
    box-shadow: 1px 1px 10px white;
}

.sidebar-guest:active {
    background-color: var(--color-accent-dark);
    box-shadow: 1px 1px 10px black;
}

.sidebar-footer {
    margin-top: auto;
    display: flex;
    font-size: 0.8rem;
    width: 75%;
    justify-content: space-between;
}

.sidebar-logout {
    padding: 1rem;
    border-radius: 1rem;
    background-color: var(--color-primary-light);
    font-weight: bold;
    cursor: pointer;
}

.sidebar-footer a {
    font-weight: normal;
}

@media screen and (max-width: 599px) {
    .sidebar-menu {
        position: fixed;
        left: 0;
        height: 100vh;
    }

    .sidebar-menu-inner {
        left: 0;
    }
}

@media screen and (min-width: 600px) {
    .sidebar-menu {
        position: static;
        max-height: 100vh;
    }

    .sidebar-menu-inner {
        right: 0;
    }
}

.profile {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    grid-row-gap: 0.5rem;
    row-gap: 0.5rem;
}

.profile :is(h1, h2, h3, p) {
    margin: 0;
}

.profile .profile-email {
    color: #888;
    margin-bottom: 1rem;
}

.profile h3 {
    font-size: 1.3em;
    color: var(--color-primary-dark);
}

.profile > div {
    margin-bottom: 1.5rem;
}

.profile-review-update {
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    font-size: 1rem;
    background-color: var(--color-primary-light);
}

.delete-button, .details-button {
        padding: 0.5rem 1rem;
        border-radius: 1rem;
        font-size: 1rem;
        background-color: var(--color-primary-light);
        cursor: pointer;
}
.page-container {
    margin: 1rem;
    padding: 2rem 2rem 3rem;
    width: 90%;
    border-radius: 1rem;
    position: relative;
    box-shadow: 2px 2px 10px rgba(40, 40, 40, 0.6);
}

.App,
.app-container {
    display: flex;
}

.App {
    width: 100%;
    min-height: 100vh;
}

.app-error {
    padding: 1rem;
    font-size: 0.9rem;
    width: 85%;
    text-align: center;
    background-color: rgba(255, 0, 0, 0.1);
    color: rgb(200, 0, 0);
    border: 3px solid rgb(200, 0, 0);
    border-radius: 1rem;
}

.app-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
    transition: width 0.5s;
}

.page-transition-enter {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}

.page-transition-enter-active {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition: all 380ms ease-out;
}

.page-transition-exit {
    opacity: 1;
}

.page-transition-exit-active {
    opacity: 0;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    transition: all 400ms ease-out;
}

.about {
    font-size: 1rem;
}

.about .fa-github {
    font-size: 1.5rem;
    margin-right: 1rem;
}

.about .fa-file-code {
    margin-right: 1rem;
}

.about a {
    text-decoration: none;
    color: black;
}

.about p {
    margin: 0;
}
.about-profiles {
    display: flex;
    margin-top: 1.25rem;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}

.about-profile {
    display: flex;
    width: 100%;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: center;
    padding: 1rem;
    box-shadow: 2px 2px 10px rgba(80, 80, 80, 0.6);
    border-radius: 1rem;
}
.about-profile h2 {
    margin: 0;
}

.about-profile-img {
    width: 75%;
}

.about-profile-img img {
    width: 100%;
}

@media screen and (min-width: 600px) {
    .about .fa-github {
        font-size: 1.75rem;
    }

    .about-profiles {
        flex-direction: row;
        justify-content: space-around;
    }

    .about-profile {
        width: 30%;
    }
}

.search-page {
    font-size: 1rem;
}

.search-results {
    display: flex;
    margin: 0rem -2rem;
    grid-gap: 1rem;
    gap: 1rem;
    font-size: 0.8rem;
    align-items: center;
    flex-direction: column-reverse;
}

.search-list-container {
    display: flex;
    padding: 0.1rem 0.5rem;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    flex-direction: column;
    max-height: 40rem;
    overflow-y: scroll;
}

.stations-container {
    width: 100%;
    /* width: 12rem; */
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 1rem;
    cursor: pointer;
}

.stations-container :is(p, h1, h2, h3) {
    margin: 0;
}

.stations-container h3:is(h3) {
    font-size: 1.1rem;
}

.search-map {
    height: 40rem;
    flex-grow: 2;
}

.search-results,
.stations-container,
.search-map {
    border-radius: 1rem;
    box-shadow: 2px 2px 5px rgba(40, 40, 40, 0.6);
}

.search-page p {
    width: 96%;
    text-align: center;
}

.google-map {
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .search-results {
        flex-direction: row;
        padding: 0.5rem 1rem;
        margin: 0;
    }

}

.details {
    font-size: 1rem;
}

.review-text {
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 6px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
}

.add-review {
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    font-size: 1rem;
    background-color: var(--color-primary-light);
    cursor: pointer;
}

.plug-map-container, .review-map-container {
    display: flex;
    padding-top: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    font-size: 0.8rem;
    align-items: center;
    flex-direction: column;
    margin: 0
}

.review-map-container {
    width: 96%;
}

.review-map-container h3 {
    font-size: 1.2rem;
    margin: 0;
}

.plug-map {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    width: 100%;
}

.plug-container, .reviews-container {
    padding: 0.25rem;
    padding-left: 0.5rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    align-items: center;
    margin-bottom: 2em;
    overflow-x: auto;
    width: 100%;
}

.search-results h3 {
    font-size: 1.5rem;
}

.plug-map-container,
.plug-container,
.nearby-food,
.nearby-entertainment,
.nearby-stores,
.business-details-container,
.reviews-container {
    border-radius: 1rem;
    box-shadow: 2px 2px 5px rgba(40, 40, 40, 0.6);
}

.details img {
    margin-left: 1rem;
    cursor: pointer;
}

.nearby-containter {
    align-items: center;
    margin-bottom: 2em;

}

.nearby-food {
    margin-top: 2em;
}

.nearby-food,
.nearby-entertainment,
.nearby-stores {
    display: flex;
    flex-direction: column;
    padding: 1em;
    border-radius: 6px;
    border: solid 2px black;
    margin-bottom: 2em;
    overflow-x: auto;
    width: 100%;
    margin-top: 1rem;
    align-items: center;
}

.business-container {
    display: flex;
    grid-column-gap: 1em;
    -webkit-column-gap: 1em;
            column-gap: 1em;
    padding: 1em;
    border-radius: 6px;
    padding: 1em;
    overflow-x: auto;
    width: 96%;
}

.business-details-container {
    width: 10rem;
    display: flex;
    flex-direction: column;
    grid-column-gap: 1em;
    -webkit-column-gap: 1em;
            column-gap: 1em;
    padding: 1em;
    min-width: 200px;
    border-radius: 6px;
    border: solid 2px black;
    padding: 0em 0.5em;
    margin-bottom: 1rem;
    cursor: pointer;
}

.google-map {
    width: 100%;
    flex-grow: 4;
}

@media only screen and (min-width: 768px) {
    .plug-map-container {
        padding-top: 0;
        padding: 1rem;
    }

    .plug-container {
        width: 10rem;
    }

    .plug-map {
        flex-direction: row;
    }

    .business-container {
        justify-content: flex-start;
    }
}

@media only screen and (min-width: 1200px) {
    .plug-map-container {
        margin-bottom: 1rem;
    }

    .plug-container {
        flex-direction: column;
        width: 20rem;
    }
}

.spinner-bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    text-align: center;
    padding-top: 6rem;
    background-color: rgba(10, 10, 10, 0.7);
    z-index: 1;
    color: white;
    font-size: 2rem;
}

.loading-spinner {
    -webkit-animation: spinner 2.5s infinite linear;
            animation: spinner 2.5s infinite linear;
    font-size: 4rem;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0) scale(1);
                transform: rotate(0) scale(1);
    }

    33% {
        -webkit-transform: rotate(120deg) scale(0.7);
                transform: rotate(120deg) scale(0.7);
    }

    66% {
        -webkit-transform: rotate(240deg) scale(1.3);
                transform: rotate(240deg) scale(1.3);
    }

    100% {
        -webkit-transform: rotate(360deg) scale(1);
                transform: rotate(360deg) scale(1);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0) scale(1);
                transform: rotate(0) scale(1);
    }

    33% {
        -webkit-transform: rotate(120deg) scale(0.7);
                transform: rotate(120deg) scale(0.7);
    }

    66% {
        -webkit-transform: rotate(240deg) scale(1.3);
                transform: rotate(240deg) scale(1.3);
    }

    100% {
        -webkit-transform: rotate(360deg) scale(1);
                transform: rotate(360deg) scale(1);
    }
}

.my-reviews {
    font-size: 1rem;
}
