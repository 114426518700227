.sidebar-menu,
.sidebar-menu-inner {
    width: 0;
    font-size: 1.2rem;
    transition: width 0.5s;
    overflow: hidden;
    height: 100%;
}

.sidebar-menu-inner {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    align-items: center;
    background-color: var(--color-primary-darkest);
    color: var(--color-primary-lightest);
    position: fixed;
    top: 0;
    height: 100vh;
}

.sidebar-menu.active {
    width: 20.5rem;
}

.sidebar-menu.active .sidebar-menu-inner {
    width: 18rem;
}

.sidebar-menu hr {
    width: 75%;
}

.sidebar-menu :is(h1, h2) {
    border-bottom: 2px solid white;
    padding: 0 1rem 0.4rem;
    margin-bottom: 0.5rem;
}

.sidebar-menu a {
    color: inherit;
    text-decoration: none;
    font-weight: bold;
}

.sidebar-near {
    cursor: pointer;
    font-weight: bold;
}

.sidebar-guest {
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    background-color: var(--color-accent-light);
    font-size: 1rem;
}

.sidebar-guest:hover {
    box-shadow: 1px 1px 10px white;
}

.sidebar-guest:active {
    background-color: var(--color-accent-dark);
    box-shadow: 1px 1px 10px black;
}

.sidebar-footer {
    margin-top: auto;
    display: flex;
    font-size: 0.8rem;
    width: 75%;
    justify-content: space-between;
}

.sidebar-logout {
    padding: 1rem;
    border-radius: 1rem;
    background-color: var(--color-primary-light);
    font-weight: bold;
    cursor: pointer;
}

.sidebar-footer a {
    font-weight: normal;
}

@media screen and (max-width: 599px) {
    .sidebar-menu {
        position: fixed;
        left: 0;
        height: 100vh;
    }

    .sidebar-menu-inner {
        left: 0;
    }
}

@media screen and (min-width: 600px) {
    .sidebar-menu {
        position: static;
        max-height: 100vh;
    }

    .sidebar-menu-inner {
        right: 0;
    }
}
